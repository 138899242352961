<template>
  <div>
    <div class="grid grid-cols-3 gap-2">
      <div class="p-8 flex flex-col justify-center">
        <img
          :src="viewStudent.profile.cdnURL"
          alt=""
          srcset=""
          class=" self-center rounded-full w-40 h-40 text-center"
        />
        <hr />
        <span class="font-semibold text-center text-lg py-2">
          {{ viewStudent.name }}
        </span>
        <span class="font-semibold text-center py-0" v-if="viewStudent.email != '' ">
          ( {{ viewStudent.email }} )
        </span>
      </div>
      <div class="col-span-2  p-5 relative">
        <div class="py-1 flex float-right">
          <router-link :to="'/edit/'+ viewStudent.id" class="text-indigo-600 hover:text-indigo-900 " >
                 <i class="far fa-edit"/> Edit
          </router-link>
          <span class="px-2"> | </span>
          <button class="text-indigo-600 hover:text-indigo-900 " @click="deleteStudent(viewStudent.id)">
                 <i class="far fa-trash"/> Delete
          </button>
        </div>
        <div class="py-1" >
          <span class="font-semibold">Enroll Number : </span>
          <span class="px-2">{{ viewStudent.enrollNumber }}</span>
        </div>
        <div class="py-1" v-if="viewStudent.session != '' ">
          <span class="font-semibold">Session : </span>
          <span class="px-2">{{ viewStudent.session }}</span>
        </div>
        <div class="py-1">
          <span class="font-semibold">Full Name : </span>
          <span class="px-2">{{ viewStudent.name }}</span>
        </div>
        <div class="py-1">
          <span class="font-semibold">D.O.B : </span>
          <span class="px-2">{{ viewStudent.dateofbirth }}</span>
        </div>
        <div class="py-1">
          <span class="font-semibold">Father Name : </span>
          <span class="px-2">{{ viewStudent.father_name }}</span>
        </div>
        <div class="py-1">
          <span class="font-semibold">Mother Name : </span>
          <span class="px-2">{{ viewStudent.mother_name }}</span>
        </div>
        <div class="py-1" v-if="viewStudent.email != '' ">
          <span class="font-semibold">E-mail : </span>
          <span class="px-2">{{ viewStudent.email }}</span>
        </div>
        <div class="py-1">
          <span class="font-semibold">Address : </span>
          <span class="px-2"
            >{{ viewStudent.address }} {{ viewStudent.city }} ({{
              viewStudent.pincode
            }}) {{ viewStudent.state }}</span
          >
        </div>
        <div class="py-1">
          <span class="font-semibold">Course : </span>
          <span class="px-2">{{ viewStudent.course }}</span>
        </div>
        
      </div>
      <div class="col-span-3">
        <div class="mt-0">
          <table class="min-w-full divide-y divide-gray-200 overflow-y-scroll no-scrollbar">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="pl-12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Subject Name
                </th>
                <th
                  scope="col"
                  class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Marks Obtained
                </th>
                <th
                  scope="col"
                  class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Minimum Marks
                </th>
                <th
                  scope="col"
                  class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Maximum Marks
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="result in viewStudent.results" :key="result.subject">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ result.subject }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ result.marksobtained }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ result.minmarks }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ result.maxmarks }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as fb from '../firebase.js'
import router from '../router'
import { mapState } from "vuex";
export default {
  name: "StudentDeatil",
  data() {
    return {
      route: this.$route.params.id,
    };
  },
  created() {
    console.log("Route : " + this.$route.params.id);
    const arr = this.$store.state.students.filter((x) => x.id == this.route);
    this.$store.commit("viewStudent", arr[0]);
    console.log(this.viewStudent);
  },
  computed: {
    ...mapState(["viewStudent"]),
  },
  methods:{
    deleteStudent(id){
     const choice = confirm(`Do You Want To Delete ${this.viewStudent.enrollNumber}`);
      if(choice == true){
        fb.studentCollection.doc(id).delete();
        router.push('/allstudents')
     }
     else{
       alert(`${this.$store.state.viewStudent.enrollNumber} Not Deleted`)
     }
    }
  }
};
</script>

<style>

</style>
